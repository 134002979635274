<template>
  <section class=" l-layout-cards c-recovered-card">

    <div class="c-card-content">
      <div class="c-card-content__container">
        <h2 class="c-card-content__title">
          Casos Recuperados
        </h2>

        <img src="@/assets/img/icons/heart.svg" alt="Ícone de coração"
             class="c-card-content__icon ">
      </div>

      <p class="c-card-content__cases" v-if="dataCases">
        {{ dataCases.recovered }}<span> casos </span>
      </p>
      <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>
    </div>

    <div class="c-card-content">
      <h3 class="c-card-content__second-title">Casos Descartados</h3>

      <p class="c-card-content__cases" v-if="dataCases">
        {{ dataCases.discarded }} <span> casos </span>
      </p>
      <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>
    </div>
  </section>
</template>

<script>
import { api } from "../services";

export default {
  name: "Recovered",
  data() {
    return {
      dataCases: null
    }
  },
  methods: {
    getDataForCases() {
      this.dataCases = null;

      api.get('/casos').then(response => {
        this.dataCases = response.data;
      });
    }
  },
  created() {
    this.getDataForCases();
  }
}
</script>

<style scoped>
.c-recovered-card {
  background-color: var(--recovered-card-background);
}
</style>