<template>
  <section class="l-layout-cards c-confirmed-card" role="region">

    <div class="c-card-content">
      <div class="c-card-content__container">
        <h2 class="c-card-content__title">
          Casos Ativos
        </h2>

        <img src="@/assets/img/icons/confirm.svg" alt="Ícone de confirmação" class="c-card-content__icon">
      </div>

      <p class="c-card-content__cases" v-if="dataConfirmed">
        {{ dataConfirmed.active }}<span> casos </span>
      </p>
      <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>

      <div class="c-card-content">
        <h3 class="c-card-content__second-title">Casos Confirmados</h3>

        <p class="c-card-content__cases" v-if="dataConfirmed">
          {{ dataConfirmed.confirmed }} <span> casos </span>
        </p>
        <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "../services";

export default {
  name: "Confirmed",
  data() {
    return {
      dataConfirmed: null
    }
  },
  methods: {
    getDataForConfirmed() {
      this.dataCases = null;

      api.get('/casos').then(response => {
        this.dataConfirmed = response.data;
      });
    }
  },
  created() {
    this.getDataForConfirmed();
  }
}
</script>

<style scoped>
.c-confirmed-card {
  background-color: var(--confirmed-card-background);
}
</style>