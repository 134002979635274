import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://apicovid.alfaunipac.com.br/dados"
})

export const api = {
    get(endpoint) {
        return axiosInstance.get(endpoint);
    }
}