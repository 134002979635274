<template>
  <div class="c-second-container">
    <h2 class="c-second-container__subtitle">
      Teófilo Otoni
      <span class="c-second-container__update" v-if="updatedAt">
						- Dados de: {{ updatedAt.date_at | dateForBR }}
      </span>
      <button class="btn-reload" @click="reloadPage"><img src="@/assets/img/reload.svg" alt=""></button>

      <p class="c-second-container__update-p">
        Atualizações diárias mediante liberação dos dados
        oficiais pela Prefeitura.
      </p>
    </h2>
  </div>
</template>

<script>
import {api} from "../services";

export default {
  name: "UpdatedAt",
  data() {
    return {
      updatedAt: null
    }
  },
  methods: {
    getDataForUpdate() {
      this.updatedAt = null;

      api.get('/obitos').then(response => {
        this.updatedAt = response.data;
      });
    },
    reloadPage() {
      return window.location.reload();
    }
  },
  filters: {
    dateForBR(date) {
      const newDate = new Date(date);

      return newDate.toLocaleDateString("pt-BR", {
        hour12: false,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "GMT"
      })
    }
  },
  created() {
    this.getDataForUpdate();
  }
}
</script>

<style scoped>
.c-second-container {
  padding-left: 1rem;
}


.c-second-container__subtitle {
  color: var(--subtitle-color);
  font-weight: 400;
  font-size: 1.2rem;
  text-align: left;
}


.c-second-container__update {
  font-size: 1rem;
}

.c-second-container__update-p {
  font-size: 1rem;
  padding: 25px 25px 0 0;
}

.btn-reload {
  width: 20px;
  height: 20px;
  padding: 3px;
  border-radius: 8px;
  border: none;
  background-color: #d0d0d0;
  cursor: pointer;
}

.btn-reload img {
  width: 100%;
}
</style>