<template>
  <div id="app">
    <section class="l-grid-system">
      <TheHeader />

      <main class="l-main-content" role="main">
        <!--Título-->
        <div class="c-container">
          <h1 class="c-container__title">
            Painel
            <span class="c-container__complement">Corona Vírus</span>
          </h1>
        </div>

        <!--Atualizado em-->
        <UpdatedAt />

        <!--Section cards-->
        <section class="l-group-cards" role="region">
          <Vaccine />

          <Recovered />

          <Confirmed />

          <Hospitalizations />

          <Beds />

          <Deaths />
        </section>
      </main>

      <TheFooter />
    </section>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader";
import TheFooter from "./components/TheFooter";
import UpdatedAt from "./components/UpdatedAt";
import Recovered from "./components/Recovered";
import Hospitalizations from "./components/Hospitalizations";
import Confirmed from "./components/Confirmed";
import Deaths from "./components/Deaths";
import Vaccine from "./components/Vaccine";
import Beds from "./components/Beds";

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    UpdatedAt,
    Recovered,
    Hospitalizations,
    Confirmed,
    Deaths,
    Vaccine,
    Beds
  }
}
</script>

<style>
/* ==========================================================================
    #RESET
   ========================================================================== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/*============================================================================
    #PAGE
============================================================================= */

/*
*
* Page Settings
*
* 1 - Ensure the page always fills at least the entire height of the viewport.
* 2 - Ensure the page always fills at least the entire height of the viewport.
*
*/

html {
  min-height: 100%;  /* [2] */
  font-family: 'Roboto', sans-serif;
  font-size: 93.75%; /* 15px */
}

/* ==========================================================================
      #BODY
   ========================================================================== */

/*
*
* Body section element settings.
*
*/

body {
  background-color: var(--background-page);
  line-height: 0.5rem;
  height: 100vh;
  width: 100%;
}
/* ==========================================================================
      #COLORS
   ========================================================================== */

/*
*
* Color variables used in the project.
*
*/

:root {
  --background-page: rgb(255, 255, 255);
  --title-color: rgb(0, 0, 0);
  --subtitle-color: rgb(45, 43, 43);
  --card-title-color: rgb(255, 255, 255);
  --card-subtitle-color: rgb(245, 245, 245);
  --recovered-card-background: rgb(34, 98, 75);
  --hospitalizations-card-background: rgb(48, 52, 145);
  --confirmed-card-background:  rgb(154, 24, 70);
  --death-card-background: rgb(48, 48, 48);
  --vaccinated-card-background: rgb(36, 85, 158);
  --beds-card-background: rgb(95, 44, 125);
  --shadow-color-cards: rgba(0, 0, 0, 0.25);
  --footer-text-color: rgb(35, 35, 38);
}

/* ==========================================================================
      #GRID-SYSTEM
   ========================================================================== */

/*
   *
   * Grid system for positioning the Header, Main and Footer.
   *
   */


.l-grid-system {
  display: grid;
  grid-template-areas:
		"header"
		"main"
		"footer";
  grid-template-rows: auto 1fr auto;
}

.l-grid-system > header {
  grid-area: header;
}

.l-grid-system > main {
  grid-area: main;
}

.l-grid-system > footer {
  grid-area: footer;
}

/* ==========================================================================
      #MAIN
   ========================================================================== */

/*
  * Stylization of the main content of the page.
  * Style and margin settings for the main and other content integrated
  * with c-container and c-second-container.
  */


.l-main-content {
  margin: 2rem 10rem 3rem;
  padding-top: 0.5rem;
}


.c-container {
  padding-bottom: 2rem;
}


.c-container__title {
  font-size: 1.8rem;
  color: var(--title-color);
  font-weight: 700;
  padding-left: 1rem;
  text-align: left;
}


.c-container__complement {
  font-weight: 300;
}

/* ==========================================================================
      #SECTION FOR CARDS
   ========================================================================== */

/*
   * Stylization of the main content of the page.
   */


.l-group-cards {
  display: grid;
  grid-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 4rem;
  padding-left: 1rem;
}

.l-layout-cards {
  padding: 1rem;
  width: 20rem;
  height: 13rem;
  line-height: 1.5em;
  border-radius: 0.66rem;
  box-shadow: 0px 4px 4px var(--shadow-color-cards);
}

.c-card-content__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.c-card-content__title,
.c-card-content__subtitle,
.c-card-content__cases {
  font-family: "Lato", sans-serif;
  text-align: left;
}


.c-card-content__title,
.c-card-content__second-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--card-title-color);
  letter-spacing: 0.01em;
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
}


.c-card-content__second-title {
  padding-top: 1rem;
}


.c-vaccinated-card .c-card-content__title,
.c-hospitalizations-card .c-card-content__title {
  padding-left: 4rem;
  font-size: 1.6rem;
}


.c-card-content__icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
}


.c-card-content__subtitle {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.2em;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--card-title-color);
}


.c-card-content__cases {
  font-weight: 700;
  font-size: 1.5rem;
  padding-left: 0.5rem;
  color: var(--card-subtitle-color);
  padding-bottom: 1rem;
}

.u-card-content__center {
  text-align: center;
  margin-top: 1.5rem;
}

span.c-loading {
  display: inline-block;
  width: 20px;
  height: 20px;
}

span.c-loading img {
  width: 100%;
}

/* ==========================================================================
      #RESPONSIVENESS
   ========================================================================== */

/*
* The media queries responsible for the responsive layout.
*/


@media (max-width: 850px) {
  html {
    font-size: 70%;
  }

  .l-grid-system {
    grid-template-rows: auto auto 1fr auto auto;
  }

  .c-page-header__image {
    width: 200px;
  }

  .l-main-content {
    margin: 0 auto;
    padding: 2rem;
  }

  .c-second-container__subtitle {
    line-height: 1em;
  }

  .l-group-cards {
    display: grid;
    grid-gap: 1.5rem;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 4rem;
    padding-left: 1rem;
  }
}


@media (min-width: 851px) and (max-width: 1104px) {
  .l-group-cards {
    display: grid;
    grid-gap: 1.5rem;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 4rem;
    padding-left: 1rem;
  }
}

</style>
