<template>
  <section class="l-layout-cards c-vaccinated-card" role="region">

    <div class="c-card-content">
      <div class="c-card-content__container">
        <h2 class="c-card-content__title">
          Vacinômetro
        </h2>

        <img src="@/assets/img/icons/seringa.svg" alt="Ícone de seringa"
             class="c-card-content__icon">
      </div>

      <div class="c-card-content">
        <h3 class="c-card-content__subtitle">1&ordf; dose</h3>
        <p class="c-card-content__cases" v-if="dataVaccine">
          {{ dataVaccine[0].applied_dose }}<span> vacinados </span>
        </p>
        <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>

        <h3 class="c-card-content__subtitle">2&ordf; dose</h3>
        <p class="c-card-content__cases" v-if="dataVaccine">
          {{ dataVaccine[1].applied_dose }} <span> vacinados </span>
        </p>
        <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>
      </div>
    </div>

  </section>
</template>

<script>
import { api } from "../services";

export default {
  name: "Vaccine",
  data() {
    return {
      dataVaccine: null
    }
  },
  methods: {
    getDataForVaccine() {
      this.dataVaccine = null;

      api.get('/vacina').then(response => {
        this.dataVaccine = response.data;
      });
    }
  },
  created() {
    this.getDataForVaccine();
  }
}
</script>

<style scoped>
.c-vaccinated-card {
  background-color: var(--vaccinated-card-background);
}
</style>