<template>
  <footer class="l-page-footer" role="contentinfo">

    <p class="c-page-footer__text">
      Desenvolvido pelo curso de Sistemas de Informação da Faculdade AlfaUnipac.
    </p>
  </footer>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style scoped>
/* ==========================================================================
      #FOOTER
   ========================================================================== */

/*
   * Stylization of the page footer.
   * 1 - Footer description, containing the copyright and the team
   *     responsible for the project.
   */


/* [1] */
.c-page-footer__text {
  text-align: center;
  line-height: 20px;
  padding: 0.5rem 0 1rem 0;
  color: var(--footer-text-color);
}
</style>