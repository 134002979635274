<template>
  <section class="l-layout-cards c-beds-card" role="region">

    <div class="c-card-content">
      <div class="c-card-content__container">
        <h2 class="c-card-content__title">
          Leitos de Enfermaria
        </h2>

        <img src="@/assets/img/icons/bed.svg" alt="Ícone de cama hospitalar" class="c-card-content__icon">
      </div>

      <p class="c-card-content__cases" v-if="dataBeds">
        {{ dataBeds.beds }}<span> leitos </span>
      </p>
      <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>

      <h3 class="c-card-content__second-title">Leitos ocupados</h3>
      <p class="c-card-content__cases" v-if="dataBeds">
        {{ dataBeds.busy_beds }} <span> leitos </span>
      </p>
      <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>
    </div>
  </section>
</template>

<script>
import { api } from "../services";

export default {
  name: "Beds",
  data() {
    return {
      dataBeds: null
    }
  },
  methods: {
    getDataForBeds() {
      this.dataBeds = null;

      api.get('/leitos-enfermaria').then(response => {
        this.dataBeds = response.data;
      });
    }
  },
  created() {
    this.getDataForBeds();
  }
}
</script>

<style scoped>
.c-beds-card {
  background-color: var(--beds-card-background);
}
</style>