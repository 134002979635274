<template>
  <header class="l-page-header" role="banner">
    <img src="@/assets/img/covid-logo.svg" alt="Imagem do logotipo da página do site" class="c-page-header__image" />
  </header>
</template>

<script>
export default {
  name: "TheHeader"
}
</script>

<style scoped>
/* ==========================================================================
      #HEADER
   ========================================================================== */

/*
* Stylization of the page header.
* 1 - Customization of the header.
* 2 - Page logo styles.
*/
/* [1] */
.l-page-header {
  height: 5rem;
}


/* [2] */
.c-page-header__image {
  width: 250px;
  margin: 1rem;
  padding: 0.5rem;
}
</style>