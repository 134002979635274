<template>
  <section class="l-layout-cards c-death-card" role="region">

    <div class="c-card-content">
      <div class="c-card-content__container">
        <h2 class="c-card-content__title">
          Óbitos Confirmados
        </h2>

        <img src="@/assets/img/icons/death.svg" alt="Ícone de caveira com o vírus" class="c-card-content__icon">
      </div>

      <p class="c-card-content__cases u-card-content__center">
        <span v-if="dataDeaths">{{ dataDeaths.confirmed }}</span>
        <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>
        <span> óbitos</span> </p>
    </div>
  </section>
</template>

<script>
import { api } from "../services";

export default {
  name: "Deaths",
  data() {
    return {
      dataDeaths: null
    }
  },
  methods: {
    getDataForDeaths() {
      this.dataDeaths = null;

      api.get('/obitos').then(response => {
        this.dataDeaths = response.data;
      });
    }
  },
  created() {
    this.getDataForDeaths();
  }
}
</script>

<style scoped>
.c-death-card {
  background-color: var(--subtitle-color);
}
</style>