<template>
  <section class="l-layout-cards c-hospitalizations-card" role="region">

    <div class="c-card-content">
      <div class="c-card-content__container">
        <h2 class="c-card-content__title">
          Leitos de UTI
        </h2>

        <img src="@/assets/img/icons/pharmacy.svg"
             alt="Ícone de símbolo hospitalar"
             class="c-card-content__icon">
      </div>

      <div class="c-card-content">
        <h3 class="c-card-content__subtitle">Leitos</h3>


        <p class="c-card-content__cases " v-if="dataHospitalizations" >
          {{ dataHospitalizations.beds }} <span>leitos</span>
        </p>
        <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>

        <h3 class="c-card-content__subtitle">Leitos ocupados</h3>
        <p class="c-card-content__cases" v-if="dataHospitalizations">
          {{dataHospitalizations.busy_beds}} <span>leitos</span>
        </p>
        <span class="c-loading" v-else><img src="@/assets/img/loading.svg" alt=""></span>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "../services";

export default {
  name: "Hospitalizations",
  data() {
    return {
      dataHospitalizations: null
    }
  },
  methods: {
    getDataForHosp() {
      this.dataHospitalizations = null;

      api.get('/internacoes-uti').then(response => {
        this.dataHospitalizations = response.data
      });
    }
  },
  created() {
    this.getDataForHosp();
  }
}
</script>

<style scoped>
.c-hospitalizations-card {
  background-color: var(--hospitalizations-card-background);
}
</style>